.smart-rest-orde-tab .nav-tabs .nav-link {
  width: 50% !important;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: initial !important;
  opacity: 0.4 !important;
}

.smart-rest-orde-tab nav.card-header-tabs {
  border-bottom: 1px solid #dee2e6 !important;
}

.smart-rest-order-menu .nav-item .nav-link {
  opacity: 0.5;
}

.smart-rest-orde-tab .nav-item.active,
.smart-rest-order-menu .nav-item.active,
.smart-rest-order-menu .nav-item .nav-link.active {
  border: 0px !important;
  border-bottom: 3px solid  !important;
  font-weight: 700 !important;
  opacity: 1 !important;
  border-radius: 24px 24px 0 0 !important;
}
.smart-rest-order-menu .nav-item.active,
.smart-rest-order-menu .nav-item .nav-link.active {
  background-color: unset !important;
}

.smart-rest-order-menu .tab-title {
  font-size: 17px;
  text-align: center;
}

.sf-thin-table-row td,
.sf-thin-table-row th {
  padding: 2px !important;
}

.smart-rest-orde-tab .card-header-tabs {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sf-notablemsg {
  text-align: center;
  padding-top: 3em;
  font-weight: 200;
  font-size: medium;
}
.smart-rest-order-menu .nav-tabs .nav-link {
  padding: 8px 23px !important;
}

.sf-bill-section {
  min-height: 65vh !important;
  padding: 10px !important;
  display: flex !important;
  flex-direction: column !important;
}

.sf-gst {
  color: #d82626;
  border-radius: 5px;
  border: 1px solid;
  padding: 3px 5px;
}

.transfer-table-select {
  border: 1px solid #00800b !important;
  background-color: #ecffed  !important;
  box-shadow: -1px 0px 4px 3px #eee;
  transform: scale(1.05);
}

.tblcard{
  border-radius: 9px 9px 0px 0px;
}

.food-item-search {
  max-width: 100%;
  max-width: 500px;
  min-width: 265px !important;
  width: 500px;
}
.search-icon {
  font-size: 33px !important;
  color:"" ;
}

.food-item-search .MuiFilledInput-input {
  padding: 10px 9px !important;
}

.sf-button-icon {
  font-size: 10px !important;
  padding: 5px 5px !important;
  /* height: 28px !important; */
}

.sf-button-icon-menu {
  font-size: 12px !important;
  padding: 5px 5px !important;
  height: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.sf-button-icon-md {
  height: 35px !important;
}

.sf-veg-nonveg-icon {
  width: 22px;
  display: flex;
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 2px;
  margin: 5px;
  background-color: #fff;
}

.sf-veg-nonveg-icon-billing {
  position: absolute;
  width: 18px;
  left: 50px;
  top:-30px;
  margin: 3px;
  background-color: #fff;
}

.sf-order-title {
  align-items: center;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

.sf-category-name {
  margin-top: 5px;
  color: #282c3f92;
  width: 70%;
  font-size: 12px;
}
.sf-foodmenu-name {
  font-size: 16px;
}
.order-title-name-left {
  flex: 1 0 auto;
  font-size: 17px;
  text-align: end;
  font-weight: 600;
  /* background: #fff; */
  color: #0c3765;
  text-transform: uppercase;
}

.order-created-right {
  /* flex: 1 0 auto; */
  margin-left: 14px;
  color: #0c3765;
  text-align: end;
}

#smart-rest-order-menu-tab-null {
  opacity: 1 !important;
}

.MuiTableBody-root td button {
  padding: 0 10px !important;
}

.sf-mobile-tablet-category .category-section-mobile button {
  height: auto;
  width: 100%;
}

.padding-right-zero {
  padding-right: 0px !important;
}

.padding-left-zero {
  padding-left: 0px !important;
}
.billing-details-section {
  /* max-height: 65vh !important;
  min-height: 65vh !important;
  overflow-y: auto !important; */
}

.sf-billing-mobile-view .sf-mobile-navigation-button {
  display: none;
}

.food-item-list-container,
.category-section-mobile {
  /* max-height: 75vh; */
  /* overflow: auto; */
  margin: 1px;
}

.active-category {
  /* color: #fff !important; */
  /* background: "" !important; */
}

/* Create a custom checkbox */
/* .sf-custom-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 5px
  } */

.sf-custom-checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px !important;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.sf-custom-checkbox:hover,
.sf-custom-radio:hover {
  border: 1px solid ;
}
.sf-custom-checkbox:active,
.sf-custom-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.sf-custom-checkbox:checked {
  background-color:"" ;
  border: 1px solid ;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.sf-custom-checkbox:checked:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: black;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid ;
  border-radius: 50%;
  outline: none;
}
input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px  inset;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: black;
}
/* Custom Checkbox end */

.sf-variant-footer-cart {
  border: 1px solid;
    border-radius: 9px;
    padding: 6px 2px;
}

.sf-variant-footer-cart button {
  color: "";
}

.sf-variant-footer-cart .food-qty {
  margin: 0px 15px !important;
}

/*======================= KITCHEN CSS START ========================================*/

.sf-kitchen-item .table-responsive {
  height: 225px;
  max-height: 300px;
  margin-top: 13px;
}

.sf-kitchen-table thead tr{
  position: sticky;
  top: 0;
  z-index: 1;
}
.sf-kitchen-table thead th{
  padding: 5px 6px !important;
}
.sf-kitchen-table thead tr{
  border-radius: 8px;
}



.sf-kitchen-table td{
  padding: 2px 3px !important;
  vertical-align: initial !important;
}

.sf-kitchen-table tr {
  line-height: 1.5 !important;
}

.sf-kitchen-card .table-title {
  text-align: left;
  padding: 3px 10px;
  margin: 5px 0;
  /* display: inline-block; */
  border-radius: 6px;
}

.sf-kitchen-card .card-body {
  padding: 10px!important;
}

.sf-kitchen-card {
  margin-bottom: 20px!important
}

.sf-kitchen-container {
  /* max-height: 90vh; */
  overflow: hidden;
}

.sf-kitchen-item {
  padding: 5px 0 0px 0;
  max-height: 85vh;
  overflow: auto;
}

/*======================= KITCHEN CSS END ========================================*/

/* ===================== RESPONSIVE MEDIA QUERY  START============= */

@media only screen and (max-width: 576px) {
  .food-item-list-container {
    padding-bottom: 51px!important;
    width: 100%!important;
  }

  .sf-button-icon {
    font-size: 7px !important;
    padding: 7px !important;
    /* height: 28px !important; */
  }

  .sf-button-icon svg {
    font-size: 30px!important;
  }

  .food-item-list, .category-list-col {
    padding-right: 0px!important;
  }
  .input-wrap {
    height: 2.5rem!important;
  }
  .order-history-filter-btn {
    /* height: 40px!important; */
  }

  .search-bar-section {
    margin:  2px 0!important;
  }

  .layout-change-btn {
    padding: 2px!important;
    height: 2.3rem!important;
  }

  .category-section {
    margin-right: -2px!important;
  }

  .food-item-list .food-item-list-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.food-item-list-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

  .smart-rest-order-menu, .food-item-list {
    height: 100%;
  }

  .smart-rest-order-menu .mobile-padding-zero {
    padding: 0px!important;
  }

  .sf-mobile-tablet-category .category-section-mobile {
    display: flex;
    height: unset!important;
    overflow: auto;
    align-items: center;
  }

  .sf-mobile-tablet-category .category-section-mobile button {
    height: 40px!important;
    width: 100%;
  }

  .sf-mobile-tablet-category .card {
    flex: 1 0 auto;
  }

  .smart-rest-order-menu .nav-tabs .nav-link {
    padding: 5px 7px !important;
  }

  .food-item-search {
    width: 250px !important;
  }

  .sf-billing-mobile-view .sf-mobile-navigation-button {
    /* left: 42.5% !important; */
  }

  .sf-kitchen-container {
    max-height: unset;
    overflow: unset !important;
  }

  .sf-kitchen-item {
    max-height: unset;
    overflow-y: unset !important;
  }
}

@media only screen and (max-width: 700px) {
  .sf-bill-section .padding-right-zero {
    padding-right: 15px !important;
  }

  .sf-bill-section .padding-left-zero {
    padding-left: 15px !important;
  }
  /* ORDER DETAIL BILLING SECTION  */

  .sf-billing-mobile-view .smart-rest-orde-tab {
    border-radius: 24px 24px 0 0 !important;
  }

  .sf-billing-mobile-view .billing-details-section {
    max-height: 40vh !important;
    min-height: 40vh !important;
    overflow-y: auto !important;
  }

  .sf-billing-mobile-view .sf-mobile-navigation-button {
    background-color: #fff;
    display: block;
    color:"" ;
    box-shadow: 1px -1px 10px 0px  !important;
    position: absolute;
    padding-top: 1px !important;
    top: -32px;
    left: 46%;
  }

  .sf-billing-mobile-view .smart-rest-orde-tab .tab-content {
    height: 0;
    transition: all 0.4s;
  }

  .sf-slider-open .smart-rest-orde-tab .tab-content {
    height: 52vh;
  }

  .sf-kitchen-container {
    max-height: unset;
    overflow: unset !important;
  }

  .sf-kitchen-item {
    max-height: unset;
    overflow-y: unset !important;
  }
}

/* ===================== RESPONSIVE MEDIA QUERY END ============= */

/* OVERRIDDEN CLASSS */
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0c3765 !important;
  background-color: #0c3765 !important;
}

.custom-switch .custom-control-label::after {
  background-color:  !important;
}

/* =================== CUSTOM VARIANT MODEL CSS ===================== */

.clear-food-item {
  padding: 0px !important;
  line-height: 3px !important;
  height: 2px !important;
}
