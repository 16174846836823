.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: "";
  font-size: 10px;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: #BEBEBE;
} */

/*============== MATERIAL UI CUSTOMIZATION ============= */

/* INPUT TAG */
.MuiOutlinedInput-input {
  /* padding: 16.5px 14px!important; */
}

.MuiInputLabel-outlined {
  /* transform: translate(10px, 13px) scale(0.8)!important; */
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  /* transform: translate(14px, -6px) scale(0.75)!important; */
}

/*============== MATERIAL UI CUSTOMIZATION ============= */

body {
  /* overflow: hidden; */
}

#root {
  height: 100%;
}

/* SCROLL BAR DESIGN */
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  overflow: visible;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-color: #ededed;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 100px;
}

/*  */

.online-offline-alert {
  padding: 0.35rem 0.25rem !important;
  display: none;
  position: sticky;
  top: 0;
  z-index: 99999999999999999999;
}

td {
  vertical-align: middle !important;
}

.sf-word-break-all {
  word-break: break-all;
}