.sf-food-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sf-food-img{
    width: 40px ;
}

.sf-food-list-section {
    overflow-y: auto;
    max-height: 74vh;
}

.sf-food-card {
    border-radius: 5px;
    box-shadow: -2px 0px 5px 0px;
    padding: 4px 10px;
}

.sf-addtocartbtn {
    color: #fff;
}

.sf-food-category-btn {
    background-color: #073a47d6;
    border-color: #073a47d6;
    border: 1px solid #073a47d6;
}

.sf-food-category-scroll {
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    flex-wrap: nowrap;
    align-items: center;
}


.sf-food-category-scroll .card {
    flex: 1 0 auto;
    align-items: center;
    margin: 5px;
    box-shadow: 1px 1px 8px 2px;
}
.sf-food-category-scroll .card img{
    width: 50px;
}

.qty-box input {
    text-align: center;
    width: 60px;
    color: #fff;
}

.qty-box {
    background-color:"" ;
    color: #fff;
    box-shadow: 1px 1px 20px 2px #00000082;
    align-items: center;
    flex: 1 1 0%;
    border-radius: 10px;
    display: inline-flex;
}

.customizable-product {
    text-align: right;
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 6px;
}

.addcart-footer {
    position: absolute;
    bottom: 40px;
    left: 15%;
    right: 15%;
    padding: 10px 5px;
    margin: auto;
    /* background-color: #fff; */
    display: flex;
    align-items: center;
    /* border-radius: 10px; */
    box-shadow: 2px 1px 10px 3px #36a195c7;
}

.sf-veg-nonveg-icon-qrcode {
    position: absolute;
    /* width: 22px; */
    left: 0;
    top: 0;
    margin: 5px;
}