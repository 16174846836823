.smr-navbar .nav-pills .nav-link.active, 
.smr-navbar .nav-pills .show>.nav-link
 {
    background-color: #6348ad!important;
    border-color:#6348ad!important;
    color: #fff!important;
}

.smr-navbar .nav-link{
    padding: 3px 9px!important;;
    font-size: 12px;
    margin: 0px 4px!important;
    display: flex;
    align-items: center;
}

.smr-navbar .nav-link span{
    /* padding: 6.6px 2px!important; */
}

.smr-navbar #basic-navbar-nav {
    font-size: 15px!important;
    align-items: inherit!important;
}

.smr-navbar {
    padding: 0px!important;
    align-items: inherit!important;
    margin-left: auto;
    display: block;
    /* flex: 1; */
}