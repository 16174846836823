
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "MuseoSansW01-Rounded500" !important;
  
}

body {
  font-family: "MuseoSansW01-Rounded500" !important;
  font-size: .8rem!important;
  font-weight: 400!important;
  color: #3a3a3a!important;
  background: #f8f8f8!important;
}

/* Material Table UI Style */
.MuiTableCell-root , .MuiTypography-h6 {
  font-family: Oxygen, "MuseoSansW01-Rounded500" !important;
  /* padding: 0px 15px!important;
  font-size: 14px!important; */
}
.MuiTableCell-head {
  font-weight: bold!important;
}
 
.MuiButton-root  {
  font-family: Oxygen, "MuseoSansW01-Rounded500" !important;
}

.MuiInputBase-input {
  font-family: Oxygen, "MuseoSansW01-Rounded500" !important;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-family: Oxygen, "MuseoSansW01-Rounded500" !important;
}


.MuiTypography-body1, .MuiMenuItem-root {
  font-family: Oxygen, "MuseoSansW01-Rounded500" !important;
}

a{
  text-decoration: none !important;
}

#popover-contained .table td,
#popover-contained .table th {
  padding: 4px 14px!important;
}

.bs-popover-bottom>.arrow::after,
.bs-popover-bottom .popover-header::before {
  /* background-color:"" ; */
  border-bottom-color:!important;
  /* color:"" ; */
}